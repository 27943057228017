import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    orgsFormFieldChanged,
    orgsFormEdit,
    errorMessage,
    serverEnabledChange,
    localEnabledChange,
    serverLimitValue,
    getRecordingFileSizeOnServer
} from '../actions';
import { withTranslation } from 'react-i18next';
import {
    TextField,
    FormControlLabel,
    Switch,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    Typography
} from '@mui/material';
import InputMask from 'react-input-mask';
import { useEffect } from 'react';
import { showModal } from '../../../actions';

function Form({ t, org }) {
    const {
        _form,
        _participantsAvailable,
        _isPoolLicense,
        _licenses,
        _ts,
        _LDAPEnabled,
        _multiDomain,
        _defaultDomain,
        _modal,
        _orgs,
        _errorMessage,
        _orgRecordingLimitEnabled,
        _fileSize,
        _disabledBtn
    } = useSelector((state) => {
        return {
            _form: state.users.orgsForm,
            _participantsAvailable: state.licenses.participantsAvailable,
            _isPoolLicense: state.app.settings.isPoolLicense,
            _licenses: state.licenses.licenses,
            _ts: state.app.settings.ts,
            _LDAPEnabled: state.app.settings.LDAPEnabled,
            _modal: state.main.modal,
            _multiDomain: state.app.settings.multiDomain,
            _defaultDomain: state.app.settings.domain,
            _orgs: state.users.orgs,
            _errorMessage: state.users.orgsForm.errorMessage,
            _orgRecordingLimitEnabled:
                state.app.settings.orgRecordingLimitEnabled,
            _fileSize: state.users.fileSize,
            _disabledBtn: state.main.modal.disabledBtn
        };
    });

    const dispatch = useDispatch();
    const isEditingOrgs = _modal.title === 'orgs.edit.title';
    let domainName =
        isEditingOrgs && _form.domain === '' ? _defaultDomain : _form.domain;

    useEffect(() => {
        if (org && org._id) dispatch(orgsFormEdit({ org: { ...org } }));
    }, [org]);

    const [errorMessageLimit, setErrorMessageLimit] = useState(false);
    useEffect(() => {
        dispatch(
            showModal({
                disabledBtn: false
            })
        );
        if (errorMessageLimit) {
            dispatch(
                showModal({
                    disabledBtn: true
                })
            );
        }
    }, [errorMessageLimit]);
    function renderPoolFields() {
        const participantsAvailable =
            org && org.participants
                ? org.participants + _participantsAvailable
                : _participantsAvailable;
        return (
            <>
                <TextField
                    variant="outlined"
                    size="small"
                    margin="dense"
                    fullWidth
                    required
                    error={_form.participants > participantsAvailable}
                    helperText={t('orgs.participantsAvailable', {
                        participantsAvailable
                    })}
                    label={t('orgs.participants')}
                    name="participants"
                    value={_form.participants}
                    onChange={(event) =>
                        dispatch(
                            orgsFormFieldChanged({
                                field: event.target.name,
                                value: event.target.value
                            })
                        )
                    }
                />

                <TextField
                    variant="outlined"
                    size="small"
                    margin="dense"
                    fullWidth
                    required
                    label={t('orgs.meetsPerTime')}
                    name="meetsPerTime"
                    value={_form.meetsPerTime}
                    onChange={(event) =>
                        dispatch(
                            orgsFormFieldChanged({
                                field: event.target.name,
                                value: event.target.value
                            })
                        )
                    }
                />
            </>
        );
    }

    function renderRoomFields() {
        const licenses = _licenses.filter((license) => {
            if (
                (!license.used ||
                    (org && org._id && org.licenses.includes(license._id))) &&
                license.endTime * 1000 > _ts
            )
                return license;
        });

        return (
            <FormControl
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
            >
                <InputLabel id="select-licenses">
                    {licenses.length > 0
                        ? t('orgs.licenses')
                        : t('orgs.noLicenses')}
                </InputLabel>
                <Select
                    label={t('orgs.licenses')}
                    labelId="select-licenses"
                    multiple
                    renderValue={(value) =>
                        licenses
                            .filter((license) => {
                                if (value.includes(license._id)) return true;
                            })
                            .map(
                                (license) =>
                                    `ID: ${license.number} (${license.participants} ${t('licenses.participantsCount')})`
                            )
                            .join(', ')
                    }
                    name="licenses"
                    value={_form.licenses}
                    onChange={(event) =>
                        dispatch(
                            orgsFormFieldChanged({
                                field: event.target.name,
                                value: event.target.value
                            })
                        )
                    }
                    disabled={licenses.length === 0}
                >
                    {licenses.map((license) => (
                        <MenuItem key={license._id} value={license._id}>
                            <Checkbox
                                checked={_form.licenses.includes(license._id)}
                            />
                            <ListItemText
                                primary={`ID: ${license.number} (${license.participants} ${t('licenses.participantsCount')})`}
                            />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    }

    return (
        <form onSubmit={() => { }}>
            <TextField
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
                required
                disabled={org && org.name === 'default'}
                label={t('orgs.name')}
                name="name"
                value={_form.name}
                onChange={(event) => {
                    dispatch(
                        orgsFormFieldChanged({
                            field: event.target.name,
                            value: event.target.value
                        })
                    );
                }}
            />
            {_multiDomain ? (
                <TextField
                    variant="outlined"
                    size="small"
                    margin="dense"
                    fullWidth
                    required
                    disabled={org && org.name === 'default'}
                    label={t('orgs.title_domain')}
                    name="domain"
                    value={domainName}
                    onChange={(event) => {
                        dispatch(
                            orgsFormFieldChanged({
                                field: event.target.name,
                                value: event.target.value.replace('@', '')
                            })
                        );
                    }}
                />
            ) : null}

            {_LDAPEnabled ? (
                <TextField
                    variant="outlined"
                    size="small"
                    margin="dense"
                    fullWidth
                    label={t('orgs.bindDN')}
                    error={_errorMessage}
                    helperText={
                        _errorMessage
                            ? t('orgs.edit.binddn_already_exists')
                            : null
                    }
                    name="bindDN"
                    value={_form.bindDN}
                    onChange={(event) => {
                        dispatch(
                            orgsFormFieldChanged({
                                field: event.target.name,
                                value: event.target.value
                            })
                        );
                        if (event.target.value.length > 0) {
                            let newArrayBind = [];
                            _orgs.find((item) => {
                                if (item.bindDN !== org.bindDN) {
                                    newArrayBind.push(item);
                                }
                            });
                            newArrayBind.forEach((item) => {
                                if (item.bindDN === event.target.value) {
                                    !_errorMessage &&
                                        dispatch(errorMessage(true));
                                } else {
                                    _errorMessage &&
                                        dispatch(errorMessage(false));
                                }
                            });
                        }
                    }}
                />
            ) : null}
            {_isPoolLicense ? renderPoolFields() : renderRoomFields()}
            <InputMask
                mask="99.99.9999"
                disabled={org && org.name === 'default'}
                value={_form.exp}
                onChange={(event) =>
                    dispatch(
                        orgsFormFieldChanged({
                            field: event.target.name,
                            value: event.target.value
                        })
                    )
                }
            >
                {() => (
                    <TextField
                        variant="outlined"
                        size="small"
                        margin="dense"
                        fullWidth
                        required
                        disabled={org && org.name === 'default'}
                        helperText={t('orgs.expHelperText')}
                        label={t('orgs.exp')}
                        name="exp"
                    />
                )}
            </InputMask>
            {_orgRecordingLimitEnabled ? (
                <>
                    <Typography
                        sx={{ m: '1rem 0 0.5rem 0', fontSize: '1.1rem' }}
                    >
                        {t('orgs.settingsTitleRecording')}
                    </Typography>

                    <FormControlLabel
                        label={t('orgs.localRecording')}
                        labelPlacement="end"
                        control={
                            <Switch
                                name="localEnabled"
                                checked={_form.recordingLimit.localEnabled}
                                onChange={(event) =>
                                    dispatch(
                                        localEnabledChange(
                                            !_form.recordingLimit.localEnabled
                                        )
                                    )
                                }
                                color="primary"
                            />
                        }
                    />

                    <FormControlLabel
                        label={t('orgs.serverRecording')}
                        labelPlacement="end"
                        control={
                            <Switch
                                name="serverEnabled"
                                checked={_form.recordingLimit.serverEnabled}
                                onChange={(event) =>
                                    dispatch(
                                        serverEnabledChange(
                                            !_form.recordingLimit.serverEnabled
                                        )
                                    )
                                }
                                color="primary"
                            />
                        }
                    />
                    <TextField
                        variant="outlined"
                        size="small"
                        margin="dense"
                        fullWidth
                        required
                        disabled={
                            _form.recordingLimit.serverEnabled ? false : true
                        }
                        sx={{
                            marginTop: '0.5rem'
                        }}
                        placeholder="0"
                        defaultValue={
                            _form.recordingLimit.serverLimitValue === 0
                                ? 0
                                : null
                        }
                        type="number"
                        inputProps={{
                            inputmode: 'numeric',
                            pattern: '[0-9]*'
                        }}
                        error={errorMessageLimit}
                        helperText={
                            errorMessageLimit
                                ? t('orgs.recordingVolume', {
                                    recording: (
                                        _fileSize /
                                        1024 /
                                        1024 /
                                        1024
                                    ).toFixed(3)
                                })
                                : null
                        }
                        label={t('orgs.serverLimitValue')}
                        name="limitServer"
                        value={
                            _form.recordingLimit.serverLimitValue === 0
                                ? null
                                : _form.recordingLimit.serverLimitValue
                        }
                        onKeyDown={(event) => {
                            if (
                                event.key === 'e' ||
                                event.key === 'E' ||
                                event.key === '-' ||
                                event.key === '+'
                            ) {
                                event.preventDefault();
                            }
                        }}
                        InputProps={{
                            inputProps: {
                                min: 0
                            }
                        }}
                        onChange={(event) => {
                            const value = Number(event.target.value);
                            if (!isNaN(value)) {
                                dispatch(serverLimitValue(value));
                                dispatch(getRecordingFileSizeOnServer(org._id));
                                if (
                                    value > _fileSize / 1024 / 1024 / 1024 ||
                                    value === 0
                                ) {
                                    setErrorMessageLimit(false);
                                } else {
                                    setErrorMessageLimit(true);
                                }
                            }
                        }}
                    />
                </>
            ) : null}
        </form>
    );
}

export default withTranslation()(Form);
