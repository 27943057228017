import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    showConfirmDialog,
    showModal
} from '../../actions';
import {
    userDel,
    userEdit,
    usersFormClear
} from './actions';
import {
    Chip
} from '@mui/material';
import Form from './Form';
import { USER_DEFAULT_ADMIN_NAME, USER_PERMS } from '../../../const';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
function Users({ users, defaultName }) {
    const { _user, _LDAPEnabled, _multiDomain } = useSelector(state => {
        return {
            _user: state.app.user,
            _LDAPEnabled: state.app.settings.LDAPEnabled,
            _multiDomain: state.app.settings.multiDomain,
        }
    })

    const dispatch = useDispatch();
    if (Array.isArray(users) && users.length > 0)
        return (
            <>
                {
                    users.map(user => {
                        let icon = <AccessibilityIcon />;
                        if (user.isAdmin)
                            icon = <ManageAccountsIcon />;
                        else if (user.allowArchive && user.role === 'secretary')
                            icon = <AccessibilityIcon />;
                        else if (user.allowArchive && user.role === 'user')
                            icon = <SettingsAccessibilityIcon />
                        else if (user.role === 'user')
                            icon = <SettingsAccessibilityIcon />
                        return (

                            <div key={user._id} className='user' >

                                <Chip
                                    variant="outlined"
                                    icon={icon}
                                    label={(user.username.indexOf('@') !== -1 && _multiDomain) ? user.username.split('@')[0] + ': ' + user.name : user.username + ': ' + user.name}
                                    onClick={
                                        _user.perms.includes(USER_PERMS.USER_EDIT) && user.username !== USER_DEFAULT_ADMIN_NAME || _user.username === USER_DEFAULT_ADMIN_NAME ?
                                            () => {
                                                dispatch(
                                                    showModal({
                                                        title: 'orgs.editUser',
                                                        form: <Form user={user} defaultName={defaultName} />,
                                                        actionButton: 'users.edit.button',
                                                        action: userEdit({ id: user._id }),
                                                        closeAction: { action: usersFormClear() }
                                                    })
                                                )
                                            } : null

                                    }
                                    onDelete={
                                        !_LDAPEnabled ?
                                            _user.perms.includes(USER_PERMS.USER_DELETE) && _user._id !== user._id && user.username !== USER_DEFAULT_ADMIN_NAME ?
                                                () => {
                                                    dispatch(
                                                        showConfirmDialog({
                                                            title: 'users.del.title',
                                                            text: {
                                                                key: 'users.del.text',
                                                                vars: {
                                                                    user: { login: user.username, name: user.name }
                                                                }
                                                            },
                                                            actionButton: 'users.del.delete',
                                                            action: userDel({ id: user._id })
                                                        })
                                                    )


                                                } : null
                                            : null
                                    }
                                />
                            </div>
                        )
                    })
                }
            </>
        )

    return null;

}

export default (Users);
