import { CONNECTORS_LOAD, CONNECTORS_LOADED, CONNECTOR_ADD, CONNECTOR_DELETE, CONNECTOR_EDIT, CONNECTOR_TEST, EDIT_CONNECTOR_STATE, SELECT_PROTO, SETTINGS_FORM_CLEAR, TEST_CONNECTION, USERS_FORM_FIELD_CHANGED_LDAP, CONNECTOR_SYNC, RELOAD_SYNC_CONNECTORS, NO_CHECK_CERT, ADD_CERTIFICATE, CHANGE_BG_SYNC_INTERVAL } from "./actionTypes";

export function selectProto(payload) {
    return {
        type: SELECT_PROTO,
        payload
    }
}
export function noCheckCert(payload) {
    return {
        type: NO_CHECK_CERT,
        payload
    }
}
export function usersFormFieldChangedLDAP(payload) {
    return {
        type: USERS_FORM_FIELD_CHANGED_LDAP,
        payload
    }

}
export function settingsFormClear() {
    return {
        type: SETTINGS_FORM_CLEAR
    }
}

export function connectorAdd() {
    return {
        type: CONNECTOR_ADD,
    }
}
export function connectorTest() {
    return {
        type: CONNECTOR_TEST,
    }
}
export function connectorsLoad() {
    return {
        type: CONNECTORS_LOAD
    }
}
export function connectorsLoaded(payload) {
    return {
        type: CONNECTORS_LOADED,
        payload
    }
}
export function connectorDelete(payload) {
    return {
        type: CONNECTOR_DELETE,
        payload
    }
}
export function connectorEdit(payload) {
    return {
        type: CONNECTOR_EDIT,
        payload
    }
}
export function editConnectorState(payload) {
    return {
        type: EDIT_CONNECTOR_STATE,
        payload
    }
}
export function testConnection(payload) {
    return {
        type: TEST_CONNECTION,
        payload
    }
}

export function connectorSync(payload) {
    return {
        type: CONNECTOR_SYNC,
        payload
    }
}
export function reloadSyncConnectors(payload) {
    return {
        type: RELOAD_SYNC_CONNECTORS,
        payload
    }
}
export function addCertificate(payload) {
    return {
        type: ADD_CERTIFICATE,
        payload
    }
}
export function changeBgSyncInterval(payload) {
    return {
        type: CHANGE_BG_SYNC_INTERVAL,
        payload
    }
}
