import React, { useCallback } from "react"
import { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { baseStyle, acceptStyle, activeStyle, rejectStyle } from "../../const";
import { Box } from "@mui/material";
function Dropzone({ fileHandle }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach(file => {
      dispatch(fileHandle(file));
    });
  }, []);

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop,
    multiple: false,
    useFsAccessApi: false
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  return (
    <Box {...getRootProps({ style })} sx={{ width: '20%' }}  >
      <input {...getInputProps()} />
      {acceptedFiles.length !== 0 ? <Box>{t('licenses.downloadFileDrop', { fileName: acceptedFiles[0]['name'] })}</Box> : <Box>{t('licenses.uploadFileDrop')}</Box>}
    </Box>
  );
}
export default Dropzone
