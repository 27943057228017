import { Box, Card, Container, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import UsdMeet from './UsdMeet';
import JoinUsd from './JoinUsd';
import { withTranslation } from 'react-i18next';

function Usd(props) {
    let confId = '';
    let oneTimeToken = '';
    if (props.match && props.match.params) {
        confId = props.match.params.confId;
    }
    if (props.location && props.location.search) {
        oneTimeToken = new URLSearchParams(props.location.search).get(
            'one_time_token'
        );
    }

    const info = useMemo(() => {
        return {
            uslugirtToken: oneTimeToken
        };
    }, [oneTimeToken]);

    if (confId && oneTimeToken) {
        return <UsdMeet info={info} confId={confId} />;
    }

    return (
        <Box className="usdPage">
            <Container maxWidth="md">
                <Card className="usdHeadWrap">
                    <Box className="usdHeadBox">
                        <i className="usdHeadLogo" />
                        <Typography variant="h5" className="usdHeadTitle">
                            {props.t('usd.head')}
                        </Typography>
                    </Box>
                </Card>
                <Card className="usdFormWrap">
                    <Box className="usdForm">
                        <Typography variant="h6" className="usdHeadTitle">
                            {props.t('usd.connect')}
                        </Typography>
                        <JoinUsd confId={confId} />
                    </Box>
                </Card>
            </Container>
        </Box>
    );
}

export default withTranslation()(Usd);
