/* eslint-disable no-undef */
import {
    AUTH_CHECKED,
    USER_UPDATED,
    TS_UPDATED,
    LOGGED_IN,
    LOG_IN_FAILED,
    LNG_CHANGED,
    BACKDROP_TOGGLED,
    IS_LICENSES_LOADED_FILE,
    MINT_CHANNEL_CONFERENCE_INFO_UPDATED,
    MINT_CHANNEL_SETTINGS,
    MINT_CHANNEL_USER,
    MINT_CHANNEL_CREATED,
    MINT_CHANNEL_CLOSED
} from './actionTypes';
export default window.JitsiMeetJS;
const { REACT_APP_DEV, REACT_APP_HOST_PORT } = process.env;
export const isDev = Boolean(REACT_APP_DEV);
export const locationHost = isDev
    ? REACT_APP_HOST_PORT
        ? REACT_APP_HOST_PORT
        : 'dev.mintconf.ru'
    : window.location.host;

const urlBase = isDev ? window.location.origin : `https://${locationHost}`;
const backEnd = isDev
    ? REACT_APP_HOST_PORT
        ? `https://${locationHost}`
        : 'http://local.dev.mintconf.ru:9000'
    : urlBase;
const externalApi = `https://${locationHost}/libs/conference_api.min.js`;
document.domain = isDev ? locationHost.replace(/:\d+/, '') : document.domain;
let wsUrl = `wss://${locationHost}/mint-channel`;

if (isDev && !REACT_APP_HOST_PORT) {
    wsUrl = 'ws://local.dev.mintconf.ru:9000/mintchannel';
}

const appState = {
    isIzum: process.env.REACT_APP_BUILD === 'izum',
    locationHost,
    urlBase,
    backEnd,
    externalApi,
    wsUrl,
    tsUpdateInterval: 10000,
    conferenceInfo: {},
    settings: {
        ts: Date.now(),
        clientId: ''
    },
    user: {
        isAuthChecked: false
    },
    userRoles: [],
    orgsMap: {},
    isBackdropOpen: false
};

export function appReducer(state = appState, action) {
    switch (action.type) {
        case AUTH_CHECKED:
            return {
                ...state,
                user: action.payload.user
            };
        case USER_UPDATED: {
            const user = { ...state.user };
            for (const key of Object.keys(action.payload.user))
                user[key] = action.payload.user[key];
            return { ...state, user };
        }
        case TS_UPDATED:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    ts: state.settings.ts + state.tsUpdateInterval
                }
            };
        case LOGGED_IN:
            return {
                ...state,
                user: action.payload.user,
                orgsMap: action.payload.orgsMap,
                userRoles: action.payload.userRoles
            };

        case LOG_IN_FAILED:
            return {
                ...state,
                user: { ...state.user, error: action.payload.error }
            };
        case LNG_CHANGED:
            return {
                ...state,
                settings: { ...state.settings, lng: action.payload.lng }
            };
        case BACKDROP_TOGGLED:
            return { ...state, isBackdropOpen: action.enabled };
        case IS_LICENSES_LOADED_FILE:
            return {
                ...state,
                settings: { ...state.settings, isLicenseLoaded: true }
            };
        case MINT_CHANNEL_CONFERENCE_INFO_UPDATED:
            return {
                ...state,
                conferenceInfo: action.payload
            };
        case MINT_CHANNEL_SETTINGS:
            return {
                ...state,
                settings: { ...state.settings, ...action.payload }
            };
        case MINT_CHANNEL_USER:
            return {
                ...state,
                user: { isAuthChecked: true, ...action.payload }
            };
        case MINT_CHANNEL_CREATED:
            return { ...state, mintChannel: action.payload };
        case MINT_CHANNEL_CLOSED:
            return {
                ...appState,
                user: { ...appState.user, isAuthChecked: false }
            };
        default:
            return state;
    }
}
