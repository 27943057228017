import { appFetch } from "../../../../app/functions";
import { closeConfirmDialog, closeModal, showNotification } from "../../../actions";
import { TEST_STATUSES } from "../const";
import { CONNECTORS_LOAD, CONNECTOR_ADD, CONNECTOR_DELETE, CONNECTOR_EDIT, CONNECTOR_SYNC, CONNECTOR_TEST, RELOAD_SYNC_CONNECTORS } from "./actionTypes";
import { connectorsLoad, connectorsLoaded, settingsFormClear, testConnection } from "./actions";

export function settingsMiddleware({ getState, dispatch }) {
    return function (next) {
        return function (action) {
            const state = getState();
            switch (action.type) {
                case CONNECTOR_ADD: {
                    appFetch(
                        state.app.backEnd + '/api/connectors',
                        {
                            credentials: 'include',
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({
                                name: state.settings.form.name,
                                userPrincipalName: state.settings.form.userPrincipalName,
                                password: state.settings.form.password,
                                baseDN: state.settings.form.baseDN,
                                domain: state.settings.form.domain,
                                proto: state.settings.form.proto,
                                hosts: state.settings.form.hosts,
                                cert: window.btoa(state.settings.form.cert),
                                noCheckCert: state.settings.form.noCheckCert,
                                bgSyncInterval: (state.settings.form.bgSyncInterval * 3600),
                                mapping: {
                                    displayName: state.settings.form.displayName,
                                    mail: state.settings.form.mail,
                                    mobile: state.settings.form.mobile
                                }
                            })
                        },
                        (data) => {
                            if (data.status === 'ok') {
                                dispatch(showNotification({ severity: 'success', text: 'settings.ldap.' + data.status }));
                                dispatch(connectorsLoad());
                                dispatch(closeModal({ action: settingsFormClear() }));
                            }
                            else if (data.status === 'error')
                                dispatch(showNotification({ severity: 'error', text: 'settings.ldap.' + data.status }));
                            else {
                                if (data.status === 'connector_already_exists') {
                                    dispatch(showNotification({ severity: 'error', text: 'settings.ldap.' + data.status }));
                                }
                                else if (data.status === 'user_principal_name_incorrect') {
                                    dispatch(showNotification({ severity: 'error', text: 'settings.ldap.' + data.status }));
                                }
                                else if (data.status === 'binddn_incorrect') {
                                    dispatch(showNotification({ severity: 'error', text: 'settings.ldap.' + data.status }));
                                }
                                else if (data.status === 'error')
                                    dispatch(showNotification({ severity: 'error', text: 'settings.ldap.' + data.status }));
                                else if (data.status === 'name_incorrect') {
                                    dispatch(showNotification({ severity: 'error', text: 'settings.ldap.' + data.status }));
                                }
                                else if (data.status === "bg_sync_interval_incorrect") {
                                    dispatch(showNotification({ severity: 'error', text: 'settings.ldap.form.' + data.status }));

                                }
                            }
                        },
                        dispatch,
                        () => {
                            dispatch(showNotification({ severity: 'error', text: 'settings.ldap.error' }));

                        }
                    );

                    return;
                }
                case CONNECTOR_TEST: {
                    appFetch(
                        state.app.backEnd + '/api/connectors/test',
                        {
                            credentials: 'include',
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({
                                proto: state.settings.form.proto,
                                hosts: state.settings.form.hosts,
                                cert: window.btoa(state.settings.form.cert),
                                noCheckCert: state.settings.form.noCheckCert,
                                userPrincipalName: state.settings.form.userPrincipalName,
                                password: state.settings.form.password,

                            })
                        },
                        (data) => {
                            if (data.status === 'ok') {
                                dispatch(showNotification({ severity: 'success', text: 'settings.ldap.form.testStatus.success' }));
                                dispatch(testConnection(TEST_STATUSES.SUCCESS))
                            }
                            else if (data.status === 'ldap_test_error') {
                                dispatch(testConnection(TEST_STATUSES.ERROR))
                                dispatch(showNotification({ severity: 'error', text: 'settings.ldap.form.testStatus.error' }));
                            }
                            else {
                                dispatch(showNotification({ severity: 'error', text: 'settings.ldap.form.testStatus.error' }));
                                dispatch(testConnection(TEST_STATUSES.ERROR))
                            }
                        },
                        dispatch,
                        () => {
                            dispatch(showNotification({ severity: 'error', text: 'settings.ldap.ldap_test_error' }));
                        }
                    );

                    return;
                }
                case CONNECTORS_LOAD:
                    appFetch(
                        state.app.backEnd + '/api/connectors',
                        {
                            credentials: 'include'
                        },
                        (data) => {
                            if (data.status === 'ok') {
                                dispatch(
                                    connectorsLoaded({
                                        connectors: data.connectors
                                    })
                                );
                            }
                            else {
                                if (data.status == "connectors_not_found") {
                                    dispatch(
                                        connectorsLoaded({
                                            connectors: data.connectors
                                        })
                                    );
                                } else {
                                    dispatch(showNotification({ severity: 'error', text: 'settings.ldap.loadError' }));
                                }

                            }
                        },
                        dispatch,
                        () => {
                            dispatch(showNotification({ severity: 'error', text: 'settings.ldap.loadError' }));
                        }
                    );
                    return;
                case RELOAD_SYNC_CONNECTORS:
                    appFetch(
                        state.app.backEnd + '/api/connectors',
                        {
                            credentials: 'include'
                        },
                        (data) => {
                            if (data.status === 'ok') {
                                dispatch(
                                    connectorsLoaded({
                                        connectors: data.connectors
                                    })
                                );
                            }
                        },
                        dispatch,
                        () => {
                            dispatch(showNotification({ severity: 'error', text: 'settings.ldap.loadError' }));
                        },
                        !action.payload.background
                    );
                    return;
                case CONNECTOR_DELETE:
                    appFetch(
                        state.app.backEnd + '/api/connectors',
                        {
                            credentials: 'include',
                            method: 'DELETE',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({
                                id: action.payload.id
                            })
                        },
                        (data) => {
                            if (data.status === 'ok') {
                                dispatch(showNotification({ severity: 'success', text: 'settings.ldap.del.ok' }));

                                dispatch(closeConfirmDialog());
                                dispatch(connectorsLoad());
                            } else
                                dispatch(showNotification({ severity: 'error', text: 'settings.ldap.del.error' }));
                        },
                        dispatch,
                        () => {
                            dispatch(showNotification({ severity: 'error', text: 'settings.ldap.del.error' }));
                            dispatch(connectorsLoad());
                        }
                    );
                    return;
                case CONNECTOR_EDIT: {
                    appFetch(
                        state.app.backEnd + '/api/connectors',
                        {
                            credentials: 'include',
                            method: 'PUT',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({
                                id: action.payload.id,
                                name: state.settings.form.name,
                                userPrincipalName: state.settings.form.userPrincipalName,
                                password: state.settings.form.password,
                                baseDN: state.settings.form.baseDN,
                                domain: state.settings.form.domain,
                                proto: state.settings.form.proto,
                                hosts: state.settings.form.hosts,
                                cert: window.btoa(state.settings.form.cert),
                                noCheckCert: state.settings.form.noCheckCert,
                                bgSyncInterval: (state.settings.form.bgSyncInterval * 3600),
                                mapping: {
                                    displayName: state.settings.form.displayName,
                                    mail: state.settings.form.mail,
                                    mobile: state.settings.form.mobile
                                }
                            })
                        },
                        (data) => {
                            if (data.status === 'ok') {
                                dispatch(showNotification({ severity: 'success', text: 'settings.ldap.succeeded' }));
                                dispatch(closeModal({ action: settingsFormClear() }));
                                dispatch(connectorsLoad());
                            } else if (data.status === 'error') {
                                dispatch(showNotification({ severity: 'error', text: 'settings.ldap.error' }));
                            } else if (data.status === 'name_incorrect') {
                                dispatch(showNotification({ severity: 'error', text: 'settings.ldap.' + data.status }));
                            }
                            else if (data.status === "bg_sync_interval_incorrect") {
                                dispatch(showNotification({ severity: 'error', text: 'settings.ldap.form.' + data.status }));

                            }
                        },
                        dispatch,
                        () => {
                            dispatch(showNotification({ severity: 'error', text: 'settings.ldap.error' }));
                        }
                    );
                    return;
                }
                case CONNECTOR_SYNC:
                    appFetch(
                        state.app.backEnd + '/api/connectors/sync',
                        {
                            credentials: 'include',
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({
                                id: action.payload.id
                            })
                        },
                        (data) => {
                            if (data.status === 'ok') {
                                dispatch(showNotification({ severity: 'info', text: 'settings.ldap.connectors.startSync' }));
                                dispatch(connectorsLoad());
                            }
                            else if (data.status === 'connector_already_pending_sync') {
                                dispatch(showNotification({ severity: 'warning', text: 'settings.ldap.connectors.connector_already_pending_sync' }));
                            }
                            else {
                                dispatch(showNotification({ severity: 'error', text: 'settings.ldap.connectors.error' }));
                            }
                        },
                        dispatch,
                        () => {
                            dispatch(showNotification({ severity: 'error', text: 'settings.ldap.connectors.error' }));
                        }
                    );

                    return;
            }

            next(action);
        }
    }
}
