import { createStore, applyMiddleware } from 'redux';
import { rootReducer } from './reducer';
import { appMiddleware } from '../app/middleware';
import { mainMiddleware } from '../main/middleware';
import { meetsMiddleware } from '../main/content/meets/middleware';
import { meetsFilterMiddleware } from '../main/content/meets/filter/middleware';
import { archiveMiddleware } from '../main/content/meets/list/archive/middleware';
import { licensesMiddleware } from '../main/content/licenses/middleware';
import { usersMiddleware } from '../main/content/users/middleware';
import { meetMiddleware } from '../meet/middleware';
import { roomMiddleware } from '../room/middleware';
import { usdMiddleware } from '../usd/middleware';
import { settingsMiddleware } from '../main/content/settings/LDAP/middlware';
import { fileManagerMiddlware } from '../main/content/file_manager/middlware';


export const store = createStore(
    rootReducer,
    applyMiddleware(
        appMiddleware,
        mainMiddleware,
        meetsMiddleware,
        meetsFilterMiddleware,
        archiveMiddleware,
        licensesMiddleware,
        usersMiddleware,
        meetMiddleware,
        roomMiddleware,
        usdMiddleware,
        settingsMiddleware,
        fileManagerMiddlware
    )
)
