import {
    MEETS_FILTER_DATE_RANGE_CHANGED,
    MEETS_FILTER_ITEM_SELECTED,
    MEETS_FILTER_OPTION_TOGGLED
} from "./actionTypes";
import { meetsFilterItemSelected } from "./actions";
import { meetsPageChanged } from '../actions';
import { mapDaysOfWeek } from '../functions';


export const meetsFilterMiddleware = ({ getState, dispatch }) => next => action => {
    const state = getState();

    switch (action.type) {
        case MEETS_FILTER_DATE_RANGE_CHANGED:
            const [startDate, endDate] = action.dates;
            action.startDate = startDate ? Date.parse(startDate) : 0;
            action.endDate = endDate ? new Date(endDate).setDate(new Date(endDate).getDate() + 1) - 1000 : 0;
            action.days = [];
            if (startDate && endDate) {
                for (let date = action.startDate; date < action.endDate; date += 60 * 60 * 24 * 1000) {
                    action.days.push(mapDaysOfWeek(new Date(date).getDay()));
                    if (action.days.length >= 7)
                        break;
                }
            }

        case MEETS_FILTER_ITEM_SELECTED:
            if (action.field === 'orgs') {
                const orgsMap = state.app.orgsMap;
                const selectedUsers = state.meetsFilter.users;
                let usersNotInSelectedOrgs = [];
                for (const orgId of Object.keys(orgsMap)) {
                    if (!action.value.includes(orgId)) {
                        usersNotInSelectedOrgs = usersNotInSelectedOrgs.concat(Object.keys(orgsMap[orgId].users));
                    }
                }

                const usersInSelectedOrgs = selectedUsers.filter(userId => !usersNotInSelectedOrgs.includes(userId));

                if (selectedUsers.length !== usersInSelectedOrgs) {
                    dispatch(meetsFilterItemSelected('users', usersInSelectedOrgs));
                }
            }

        case MEETS_FILTER_OPTION_TOGGLED:
            if (state.meets.page > 0)
                dispatch(meetsPageChanged({ page: 0 }));
            break;
    }

    next(action);
}
