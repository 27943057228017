export async function copyToClipboard(text) {
  setTimeout(async () => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }, 0)

}
