import React from 'react';
import { showConfirmDialog } from '../../../../actions';
import { meetDelete } from '../../actions';
import { Delete as DeleteIcon } from '@mui/icons-material';
import Action from '../../../../Action';
import { useDispatch } from 'react-redux';

function Delete({ meet, isCurrent }) {
    const dispatch = useDispatch();
    if (isCurrent) return null;
    return (
        <Action
            showIfSecretary={true}
            title={'meetsList.delete'}
            icon={<DeleteIcon />}
            onClickHandle={() => {
                dispatch(
                    showConfirmDialog({
                        title: 'meetsList.deleteTitle',
                        text: {
                            key: 'meetsList.deleteText',
                            vars: { description: meet.description }
                        },
                        actionButton: 'meetsList.delete',
                        action: meetDelete({ id: meet._id })
                    })
                );
            }}
        ></Action>
    );
}

export default Delete;
