
import { Box, Button, Grid, Input } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { showNotification } from "../../../../../actions";
import { dashboardImage, dashboardLogoApplication } from "../../../actions";
import { acceptStyle, activeStyle, baseStyle, button, image, imageLogo, rejectStyle } from "../../../../../../const";

function DropzoneFile({ t, logo, applicationLogo }) {

  const dispatch = useDispatch();
  const {
    _appLogo,
    _locationHost,
    _orgCustomImageSizeLimit,
    _orgCustomImageTypes,
    _prejoinBgImage
  } = useSelector(state => {
    return {
      _appLogo: state.users.paletteSettings.appLogo,
      _locationHost: state.app.locationHost,
      _orgCustomImageSizeLimit: state.app.settings.orgCustomImageSizeLimit,
      _orgCustomImageTypes: state.app.settings.orgCustomImageTypes,
      _prejoinBgImage: state.users.paletteSettings.prejoinBgImage
    }
  });


  const locationUrl = `https://${_locationHost}`;

  const typeValidator = (file) => {
    if (file.type.startsWith("image/")) {
      if (file.size > _orgCustomImageSizeLimit) {
        // 4MB limit
        return {
          code: "size-too-large",
        };
      }
    }
    return null;
  };
  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length == 0) {
      console.log(t('orgs.paletteSettings.imageSizeError'));

      return dispatch(
        dispatch(showNotification({ severity: 'error', text: 'orgs.paletteSettings.imageSizeError', textAttr: { size: (_orgCustomImageSizeLimit / 1024) / 1024 } })));
    }
    acceptedFiles.map((file, index) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        if (applicationLogo) {
          dispatch(dashboardLogoApplication({ image: e.target.result, file: file, url: null }));
        } else {
          dispatch(dashboardImage({ image: e.target.result, file: file, }));
        }
      };
      reader.readAsDataURL(file);
      return file;
    });
  }, []);

  const acceptImageFormatObject = {};
  _orgCustomImageTypes.forEach(value => acceptImageFormatObject[value] = []);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    validator: typeValidator,
    accept: acceptImageFormatObject
  })

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  const [imageLoaded, setImageLoaded] = useState({});
  const handleLoadImage = (image) => {
    setImageLoaded((prev) => { return { ...prev, [image]: true } })

  }
  const isLoading = useMemo(
    () => !(imageLoaded.img1)
    , [imageLoaded]
  )

  const thumbs =
    <Box style={image} >

      <img
        style={image}
        src={_prejoinBgImage.url ? `${locationUrl}${_prejoinBgImage.url}` : _prejoinBgImage.image}
        alt="image"
        key={'img1'}
        onLoad={() => handleLoadImage("img1")}
      />

      {_prejoinBgImage.image || _prejoinBgImage.url ? <Button variant="contained" style={button}
        onClick={() => {
          dispatch(dashboardImage({ image: null }));
        }}>{t('orgs.paletteSettings.delete')}</Button> : null}
    </Box>;


  const thumbsLogoApplication =
    <Box
      style={imageLogo}>
      <img
        style={imageLogo}
        src={_appLogo.url ? `${locationUrl}${_appLogo.url}` : _appLogo.image}
        alt="image"
      />

      {_appLogo.image || _appLogo.url ? <Button variant="contained" style={button}
        onClick={() => {
          dispatch(dashboardLogoApplication({ appLogo: null }));
        }}>{t('orgs.paletteSettings.delete')}</Button> : null}
    </Box>


  function defaultDropzone() {
    return (

      _prejoinBgImage.image || _prejoinBgImage.url ? thumbs :
        <Grid className="dropzone" item
        >
          <Box {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <Box>{t('orgs.paletteSettings.dropzone')}</Box>

          </Box>
        </Grid>
    )
  }

  function applicationDropzone() {
    return (

      _appLogo.image || _appLogo.url ? thumbsLogoApplication :
        <Grid className="dropzone" item
        >
          <Box {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <Box>{t('orgs.paletteSettings.dropzone')}</Box>

          </Box>
        </Grid>
    )
  }
  return (
    <>

      {
        !logo && !applicationLogo && defaultDropzone()

      }

      {applicationLogo && applicationDropzone()}

    </>
  )
}
export default withTranslation()(DropzoneFile)
