import { USD_CONF_ID, USD_CONF } from './actionTypes';

export function setUsdConfId(confId) {
    return {
        type: USD_CONF_ID,
        confId
    };
}

export function setUsdConf(conf) {
    return {
        type: USD_CONF,
        conf
    };
}
