import { TEST_STATUSES } from '../const';
import {
    CONNECTORS_LOADED,
    EDIT_CONNECTOR_STATE,
    SELECT_PROTO,
    SETTINGS_FORM_CLEAR,
    USERS_FORM_FIELD_CHANGED_LDAP,
    TEST_CONNECTION,
    NO_CHECK_CERT,
    ADD_CERTIFICATE,
    CHANGE_BG_SYNC_INTERVAL
} from './actionTypes';

const settingsState = {
    connectors: [],
    form: {
        name: '',
        proto: 'ldap',
        hosts: '',
        userPrincipalName: '',
        password: '',
        displayName: 'displayName',
        mail: 'mail',
        mobile: 'mobile',
        baseDN: '',
        domain: '',
        cert: '',
        noCheckCert: false,
        bgSyncInterval: 24
    },
    testStatus: TEST_STATUSES.UNKNOWN
};

export function settingsReducer(state = settingsState, action) {
    switch (action.type) {
        case TEST_CONNECTION:
            return {
                ...state,
                testStatus: action.payload
            };
        case SELECT_PROTO:
            return {
                ...state,
                form: {
                    ...state.form,
                    proto: action.payload
                }
            };
        case USERS_FORM_FIELD_CHANGED_LDAP:
            return {
                ...state,
                form: {
                    ...state.form,
                    [action.payload.field]: action.payload.value
                }
            };
        case SETTINGS_FORM_CLEAR:
            return { ...state, form: settingsState.form };
        case CONNECTORS_LOADED:
            return {
                ...state,
                connectors: action.payload.connectors
            };
        case EDIT_CONNECTOR_STATE:
            const { name, proto, hosts, userPrincipalName, password, mapping, baseDN, domain, noCheckCert, cert, sync } = action.payload
            return {
                ...state,
                form: {
                    name: name,
                    proto: proto,
                    hosts: hosts,
                    userPrincipalName: userPrincipalName,
                    password: password,
                    displayName: mapping.displayName,
                    mail: mapping.mail,
                    mobile: mapping.mobile,
                    baseDN: baseDN,
                    domain: domain,
                    noCheckCert: noCheckCert,
                    bgSyncInterval: sync.bgSyncInterval > 0 && sync.bgSyncInterval / 3600,
                    cert: atob(cert),

                }
            };
        case NO_CHECK_CERT:
            return {
                ...state,
                form: {
                    ...state.form,
                    noCheckCert: action.payload
                }
            }
        case ADD_CERTIFICATE:
            return {
                ...state,
                form: {
                    ...state.form,
                    cert: action.payload,
                }
            }
        case CHANGE_BG_SYNC_INTERVAL:
            return {
                ...state,
                form: {
                    ...state.form,
                    bgSyncInterval: Number(action.payload)
                }
            }
        default:
            return state;
    }
}
