import React from 'react';
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import {
    VerifiedUser,
    RecentActors,
    Grade,
    MicOff,
    VideocamOff,
    Lock,
    Loop,
    MeetingRoom,
    CoPresent
} from '@mui/icons-material';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

function Options({ meet, periodicDate, t }) {
    const { _settings } = useSelector(state => (
        {
            _settings: state.app.settings
        }
    ));
    if (!(
        meet.lobby
        || (_settings.autoLobby && meet.autoLobby)
        || (meet.autoOwner)
        || meet.startAudioMuted
        || meet.startVideoMuted
        || Boolean(meet.pass)
        || meet.periodic
        || meet.room
        || meet.webinar
        || (meet.autoRecording)
    )) {
        return (
            <>
                {t('meetsList.noOptions')}
            </>
        )
    }

    return (
        <>
            {meet.lobby ?
                <Tooltip title={t('meetForm.lobbyTooltip')} arrow>
                    <VerifiedUser />
                </Tooltip>
                :
                null
            }

            {_settings.autoLobby && meet.autoLobby ?
                <Tooltip title={t('meetForm.autoLobbyTooltip')} arrow>
                    <RecentActors />
                </Tooltip>
                :
                null
            }

            {meet.autoOwner && !meet.room ?
                <Tooltip title={t('meetForm.autoOwnerTooltip')} arrow>
                    <Grade />
                </Tooltip>
                :
                null
            }

            {meet.startAudioMuted ?
                <Tooltip title={t('meetForm.startAudioMutedTooltip')} arrow>
                    <MicOff />
                </Tooltip>
                :
                null
            }

            {meet.startVideoMuted ?
                <Tooltip title={t('meetForm.startVideoMutedTooltip')} arrow>
                    <VideocamOff />
                </Tooltip>
                :
                null
            }

            {Boolean(meet.pass) ?
                <Tooltip title={t('meetForm.passEnabled') + ': ' + meet.pass} arrow>
                    <Lock />
                </Tooltip>
                :
                null
            }

            {/* If last meet is periodic show parent meet date and time */}
            {meet.periodic ?
                <Tooltip
                    title={
                        meet.id ?
                            `${t('meetForm.periodic')}: ${periodicDate(meet.id)}`
                            :
                            t('meetForm.periodic')
                    }
                    arrow
                >
                    <Loop />
                </Tooltip>
                :
                null
            }

            {/* If last meet show room indicator */}
            {meet.room ?
                <Tooltip
                    title={
                        meet.id ?
                            `${t('meetForm.roomId')}: ${meet.roomId}`
                            :
                            t('meetsList.room')
                    }
                    arrow
                >
                    <MeetingRoom />
                </Tooltip>
                :
                null
            }

            {meet.webinar ?
                <Tooltip title={t('meetsList.webinar')} arrow>
                    <CoPresent />
                </Tooltip>
                :
                null
            }

            {meet.autoRecording ?
                <Tooltip title={t('meetForm.autoRecording')} arrow>
                    <RadioButtonCheckedIcon />
                </Tooltip>
                :
                null
            }
        </>
    )
}

export default withTranslation()(Options);
