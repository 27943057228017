import { Box, Button, Grid, Input } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { showNotification } from '../../../../../actions';
import { dashboardLogo } from '../../../actions';
import {
  acceptStyle,
  activeStyle,
  baseStyle,
  button,
  image,
  imageLogo,
  rejectStyle
} from '../../../../../../const';

function DropzoneFileLogo() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    _locationHost,
    _orgCustomImageSizeLimit,
    _orgCustomImageTypes,
    _prejoinLogo
  } = useSelector((state) => {
    return {
      _locationHost: state.app.locationHost,
      _orgCustomImageTypes: state.app.settings.orgCustomImageTypes,
      _orgCustomImageSizeLimit:
        state.app.settings.orgCustomImageSizeLimit,
      _prejoinLogo: state.users.paletteSettings.prejoinLogo
    };
  });
  const locationUrl = `https://${_locationHost}`;

  const typeValidator = (file) => {
    if (file.type.startsWith('image/')) {
      if (file.size > _orgCustomImageSizeLimit) {
        // 3MB limit
        return {
          code: 'size-too-large',
          message: 'Image file is larger than 3MB'
        };
      }
    }
    return null;
  };

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length == 0) {
      return dispatch(
        showNotification({
          severity: 'error',
          text: `${t('orgs.paletteSettings.imageSizeError')}`
        })
      );
    }
    acceptedFiles.map((file, index) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        dispatch(
          dashboardLogo({
            image: e.target.result,
            file: file,
            url: null
          })
        );
      };
      reader.readAsDataURL(file);
      return file;
    });
  }, []);
  const acceptImageFormatObject = {};
  _orgCustomImageTypes.forEach(
    (value) => (acceptImageFormatObject[value] = [])
  );
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop,
    multiple: false,
    validator: typeValidator,
    accept: acceptImageFormatObject
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <>
      {_prejoinLogo.image || _prejoinLogo.url ? (
        <Box style={image}>
          <img
            style={imageLogo}
            src={
              _prejoinLogo.url
                ? `${locationUrl}${_prejoinLogo.url}`
                : _prejoinLogo.image
            }
            alt="image"
          />

          {_prejoinLogo.image || _prejoinLogo.url ? (
            <Button
              variant="contained"
              style={button}
              onClick={() => {
                dispatch(dashboardLogo({ prejoinLogo: null }));
              }}
            >
              {t('orgs.paletteSettings.delete')}
            </Button>
          ) : null}
        </Box>
      ) : (
        <Grid className="dropzone" item>
          <Box {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <Box>{t('orgs.paletteSettings.dropzone')}</Box>
          </Box>
        </Grid>
      )}
    </>
  );
}
export default DropzoneFileLogo;
