import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    restrictedMeets,
    usersFormFieldChanged,
    usresFormEdit
} from './actions';
import { TextField, FormControlLabel, Switch } from '@mui/material';
import SelectItems from './orgs/SelectItems';
import { USER_DEFAULT_ADMIN_NAME } from '../../../const';

function Form({ user, org, defaultName }) {
    const {
        _settings,
        _form,
        _modal,
        _orgId,
        _userOrgId,
        _user,
        _LDAPEnabled,
        _multiDomain
    } = useSelector((state) => {
        return {
            _settings: state.app.settings,
            _form: state.users.form,
            _modal: state.main.modal,
            _orgId: state.app.user.org._id,
            _user: state.app.user,
            _LDAPEnabled: state.app.settings.LDAPEnabled,
            _multiDomain: state.app.settings.multiDomain
        };
    });

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [selectValue, setSelectValue] = React.useState('');
    const handleChange = (event) => {
        setSelectValue(event.target.value);
    };
    useEffect(() => {
        if (_modal.title === 'orgs.editUser' && user)
            dispatch(usresFormEdit({ user }));
    }, []);

    const isEditing = _modal.title === 'orgs.editUser';
    const isFormHiddenOrgDisabled =
        _LDAPEnabled && _form.username !== USER_DEFAULT_ADMIN_NAME;
    return (
        <form onSubmit={() => { }}>
            <TextField
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
                required={!isEditing}
                disabled={isEditing}
                label={t('users.form.username')}
                name="username"
                value={
                    _form.username.indexOf('@') !== -1 && _multiDomain
                        ? _form.username.split('@')[0]
                        : _form.username
                }
                onChange={(event) => {
                    dispatch(
                        usersFormFieldChanged({
                            field: event.target.name,
                            value: event.target.value
                        })
                    );
                }}
            />
            <TextField
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
                required
                label={t('users.form.name')}
                name="name"
                disabled={isFormHiddenOrgDisabled ? true : false}
                value={_form.name}
                onChange={(event) =>
                    dispatch(
                        usersFormFieldChanged({
                            field: event.target.name,
                            value: event.target.value
                        })
                    )
                }
            />

            {isFormHiddenOrgDisabled ? null : (
                <TextField
                    variant="outlined"
                    size="small"
                    margin="dense"
                    fullWidth
                    required={!isEditing}
                    autoComplete="off"
                    label={t('users.form.password')}
                    name="password"
                    value={_form.password}
                    onChange={(event) =>
                        dispatch(
                            usersFormFieldChanged({
                                field: event.target.name,
                                value: event.target.value
                            })
                        )
                    }
                />
            )}
            {isFormHiddenOrgDisabled ? null : (
                <TextField
                    variant="outlined"
                    size="small"
                    margin="dense"
                    fullWidth
                    required={!isEditing}
                    autoComplete="off"
                    label={t('users.form.passwordConfirm')}
                    name="passwordConfirm"
                    value={_form.passwordConfirm}
                    onChange={(event) =>
                        dispatch(
                            usersFormFieldChanged({
                                field: event.target.name,
                                value: event.target.value
                            })
                        )
                    }
                />
            )}

            <TextField
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
                label={t('users.form.email')}
                name="email"
                value={_form.email}
                disabled={isFormHiddenOrgDisabled ? true : false}
                onChange={(event) =>
                    dispatch(
                        usersFormFieldChanged({
                            field: event.target.name,
                            value: event.target.value
                        })
                    )
                }
            />

            <TextField
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
                label={t('users.form.phone')}
                name="phone"
                value={_form.phone}
                disabled={isFormHiddenOrgDisabled ? true : false}
                onChange={(event) =>
                    dispatch(
                        usersFormFieldChanged({
                            field: event.target.name,
                            value: event.target.value
                        })
                    )
                }
            />
            {(user && user.isAdmin) ? null : (
                <>
                    <br />
                    <FormControlLabel
                        label={t('users.form.allowArchive')}
                        labelPlacement="end"
                        control={
                            <Switch
                                name="allowArchive"
                                checked={_form.allowArchive}
                                onChange={(event) =>
                                    dispatch(
                                        usersFormFieldChanged({
                                            field: event.target.name,
                                            value: !_form.allowArchive
                                        })
                                    )
                                }
                                color="primary"
                            />
                        }
                    />
                </>
            )}

            {(user && user.isAdmin) ? null : (
                <>
                    <br />
                    <FormControlLabel
                        label={t('users.form.restrictedMeets')}
                        labelPlacement="end"
                        control={
                            <Switch
                                name="restrictedMeets"
                                checked={_form.restrictedMeets}
                                onChange={(event) =>
                                    dispatch(
                                        restrictedMeets({
                                            field: event.target.name,
                                            value: !_form.restrictedMeets
                                        })
                                    )
                                }
                                color="primary"
                            />
                        }
                    />
                </>
            )}

            {user && user.username !== USER_DEFAULT_ADMIN_NAME ? (
                <>
                    {' '}
                    <br />
                    <SelectItems
                        user={user}
                        orgId={_orgId}
                        userOrgId={_userOrgId}
                        defaultName={defaultName}
                    />
                </>
            ) : org ? (
                <>
                    <br />
                    <SelectItems
                        org={org}
                        orgId={_orgId}
                        userOrgId={_userOrgId}
                        defaultName={defaultName}
                    />
                </>
            ) : null}

            {_modal.title === 'orgs.addUser' ||
                (user && user.username === USER_DEFAULT_ADMIN_NAME) ||
                _user.username === user.name ? null : (
                <>
                    <br />
                    <FormControlLabel
                        label={t('users.form.active')}
                        labelPlacement="end"
                        control={
                            <Switch
                                name="active"
                                checked={_form.active}
                                onChange={(event) =>
                                    dispatch(
                                        usersFormFieldChanged({
                                            field: event.target.name,
                                            value: !_form.active
                                        })
                                    )
                                }
                                color="primary"
                                disabled={
                                    isFormHiddenOrgDisabled ? true : false
                                }
                            />
                        }
                    />
                </>
            )}
        </form>
    );
}

export default Form;
