import { USD_CONF_ID } from './actionTypes';
import { MINT_CHANNEL_CONFERENCE_INFO_UPDATED } from '../app/actionTypes';
import { setUsdConf } from './actions';

export const usdMiddleware =
    ({ getState, dispatch }) =>
    (next) =>
    (action) => {
        const state = getState();
        const { mintChannel } = state.app;
        const { confId } = state.usd;

        switch (action.type) {
            case USD_CONF_ID:
                mintChannel?.sendConferenceInfo(action.confId);
                break;
            case MINT_CHANNEL_CONFERENCE_INFO_UPDATED:
                if (action.payload?.confId === confId) {
                    dispatch(setUsdConf(action.payload));
                }
                break;
        }

        return next(action);
    };
