import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { TEST_STATUSES } from "../const";
import { connectorTest, testConnection } from "./actions";
import CircularProgress from '@mui/material/CircularProgress';

function TestLoader({ disabledBtn }) {
    const { t } = useTranslation();
    const { _useLdap, _testStatus, _noCheckCert } = useSelector(state => {
        return {
            _useLdap: state.settings.form.proto,
            _testStatus: state.settings.testStatus,
            _noCheckCert: state.settings.form.noCheckCert
        }
    });

    useEffect(() => {
        dispatch(testConnection(TEST_STATUSES.UNKNOWN));
    }, [_useLdap, _noCheckCert])

    const dispatch = useDispatch();
    return (
        <>
            {
                _testStatus == TEST_STATUSES.ERROR || _testStatus == TEST_STATUSES.SUCCESS ?
                    <Button fullWidth variant="contained" sx={{ pointerEvents: 'none' }} disabled={disabledBtn} color={_testStatus == TEST_STATUSES.ERROR ? "error" : "success"}> {t(`settings.ldap.form.testStatus.${_testStatus}`)}</Button>
                    :
                    _testStatus == TEST_STATUSES.PENDING ?
                        <Button fullWidth variant="contained" sx={{ pointerEvents: 'none' }} endIcon={<CircularProgress color="inherit" size="24px" />} color="warning">
                            {t("settings.ldap.form.testStatus.pending")}
                        </Button>
                        :
                        <Button fullWidth variant="contained" disabled={disabledBtn} onClick={
                            (event) => {
                                dispatch(connectorTest());
                                dispatch(testConnection(TEST_STATUSES.PENDING));
                            }
                        }> {t('settings.ldap.form.testBtn')}</Button>
            }
        </>
    )
}
export default TestLoader
