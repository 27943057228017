import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    Container,
    TextField,
    Button,
    Checkbox,
    FormControlLabel
} from '@mui/material';
import { useState } from 'react';

import { setUsdConfId } from './actions';
import meetStatuses from '../meet/meetStatuses';

function JoinUsd({ confId, t }) {
    const [agreement, setAgreement] = useState(false);
    const [stateConfId, setStateConfId] = useState(confId);

    const dispatch = useDispatch();

    const { _authUrl, _conf, _confId, _urlBase } = useSelector((state) => {
        return {
            _authUrl: state.app.settings.uslugirtAuthUrl,
            _conf: state.usd.conf,
            _confId: state.usd.confId,
            _urlBase: state.app.urlBase
        };
    });

    const joinUsd = useCallback(
        (event) => {
            event.preventDefault();
            dispatch(setUsdConfId(stateConfId));
        },
        [dispatch, stateConfId]
    );

    const handleClickCheckbox = useCallback(() => {
        setAgreement((prevState) => !prevState);
    }, []);

    const disableButton = useMemo(() => {
        return !(agreement && Boolean(stateConfId));
    }, [agreement, stateConfId]);

    useEffect(() => {
        if (_confId && _conf && _conf.status === meetStatuses.SHOWLINK) {
            const successUrl = `${_urlBase}/usd/${_conf.confId}`;
            const errorUrl = `${_urlBase}/usd`;
            window.location.href = `${_authUrl}?success_url=${successUrl}&error_url=${errorUrl}&sign=${_conf?.uslugirtSign}`;
        }
    }, [_authUrl, _conf, _confId, _urlBase]);

    return (
        <Container maxWidth="sm">
            <form
                onSubmit={(event) => {
                    joinUsd(event);
                }}
            >
                <TextField
                    defaultValue={stateConfId}
                    variant="outlined"
                    size="small"
                    margin="dense"
                    fullWidth
                    required
                    label={t('usd.confId')}
                    name="confId"
                    disabled={Boolean(confId)}
                    error={
                        Boolean(_conf.status) &&
                        _conf.status !== meetStatuses.SHOWLINK
                    }
                    helperText={
                        Boolean(_conf.status) &&
                        _conf.status !== meetStatuses.SHOWLINK
                            ? t(`usd.${_conf.status}`)
                            : ''
                    }
                    onChange={(event) => setStateConfId(event.target.value)}
                />

                <FormControlLabel
                    className="UsdCheckbox"
                    control={<Checkbox onChange={handleClickCheckbox} />}
                    label={t('usd.agreement')}
                />

                <Button
                    variant="contained"
                    type="submit"
                    disabled={disableButton}
                >
                    {t('usd.join')}
                </Button>
            </form>
        </Container>
    );
}

export default withTranslation()(JoinUsd);
