import { Box, Button, Grid, Input } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { showNotification } from '../../../../../actions';
import { dashboardImageApp } from '../../../actions';
import {
  acceptStyle,
  activeStyle,
  baseStyle,
  button,
  image,
  rejectStyle
} from '../../../../../../const';

function DropzoneFileApp() {
  const dispatch = useDispatch();
  const {
    _appBgImage,
    _appBgImageAsPrejoin,
    _locationHost,
    _orgCustomImageTypes,
    _orgCustomImageSizeLimit,
    _prejoinBgImage
  } = useSelector((state) => {
    return {
      _appBgImage: state.users.paletteSettings.appBgImage,
      _appBgImageAsPrejoin:
        state.users.paletteSettings.appBgImageAsPrejoin,
      _locationHost: state.app.locationHost,
      _orgCustomImageSizeLimit:
        state.app.settings.orgCustomImageSizeLimit,
      _orgCustomImageTypes: state.app.settings.orgCustomImageTypes,
      _prejoinBgImage: state.users.paletteSettings.prejoinBgImage
    };
  });
  const { t } = useTranslation();
  const locationUrl = `https://${_locationHost}`;

  const typeValidator = (file) => {
    if (file.type.startsWith('image/')) {
      if (file.size > _orgCustomImageSizeLimit) {
        // 3MB limit
        return {
          code: 'size-too-large',
          message: 'Image file is larger than 3MB'
        };
      }
    }
    return null;
  };
  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length == 0) {
      return dispatch(
        showNotification({
          severity: 'error',
          text: `${t('orgs.paletteSettings.imageSizeError')}`
        })
      );
    }
    acceptedFiles.map((file, index) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        dispatch(
          dashboardImageApp({
            image: e.target.result,
            file: file,
            url: null
          })
        );
      };
      reader.readAsDataURL(file);
      return file;
    });
  }, []);
  const acceptImageFormatObject = {};
  _orgCustomImageTypes.forEach(
    (value) => (acceptImageFormatObject[value] = [])
  );
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop,
    validator: typeValidator,
    accept: acceptImageFormatObject
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const thumbs = (
    <Box style={image}>
      <img
        style={image}
        src={
          _appBgImageAsPrejoin
            ? _prejoinBgImage.image ||
            `${locationUrl}${_prejoinBgImage.url}`
            : _appBgImage.image ||
            `${locationUrl}${_appBgImage.url}`
        }
      />
      {_appBgImage.image || _appBgImage.url ? (
        <Button
          variant="contained"
          style={button}
          onClick={() => {
            dispatch(dashboardImageApp({ image: null }));
          }}
        >
          {t('orgs.paletteSettings.delete')}
        </Button>
      ) : null}
    </Box>
  );

  return (
    <>
      {_appBgImageAsPrejoin ? (
        _prejoinBgImage.image || _prejoinBgImage.url ? (
          thumbs
        ) : (
          <Grid className="dropzone" item>
            <Box {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <Box>{t('orgs.paletteSettings.dropzone')}</Box>
            </Box>
          </Grid>
        )
      ) : _appBgImage.image || _appBgImage.url ? (
        thumbs
      ) : (
        <Grid className="dropzone" item>
          <Box {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <Box>{t('orgs.paletteSettings.dropzone')}</Box>
          </Box>
        </Grid>
      )}
    </>
  );
}
export default DropzoneFileApp;
