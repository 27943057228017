import React from 'react';
import AddUser from './AddUser';
import Edit from './Edit';
import Delete from './Delete';
import PaletteSettings from './PaletteSettings';
import { useSelector } from 'react-redux';
import { USER_PERMS } from '../../../../../const';

function Actions({ org }) {
    const { _user, _LDAPEnabled } = useSelector(state => {

        return {
            _user: state.app.user,
            _LDAPEnabled: state.app.settings.LDAPEnabled,
        }
    });

    return (
        <>
            {_user.perms.includes(USER_PERMS.ORG_THEME) ? <PaletteSettings org={org} /> : null}
            {!_LDAPEnabled ? _user.perms.includes(USER_PERMS.USER_ADD) ? <AddUser org={org} /> : null : null}
            {_user.perms.includes(USER_PERMS.ORGS) && _user.perms.includes(USER_PERMS.ORG_EDIT) ? <Edit org={org} /> : null}
            {_user.perms.includes(USER_PERMS.ORGS) && _user.perms.includes(USER_PERMS.ORG_DELETE) ? <Delete org={org} /> : null}

        </>
    )
}


export default Actions;
