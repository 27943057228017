import React, { useLayoutEffect, useState } from 'react';
import Iframe from '../meet/Iframe';
import { connect, useSelector } from 'react-redux';

function UsdMeet({ info, confId, _externalApi }) {
    const [loaded, setLoaded] = useState(false);

    const { _locationHost } = useSelector((state) => {
        return {
            _locationHost: state.app.locationHost
        };
    });

    useLayoutEffect(() => {
        const externalApi = document.createElement('script');
        externalApi.src = _externalApi;
        document.body.appendChild(externalApi);
        externalApi.onload = function () {
            setLoaded(true);
        };
    }, [_externalApi]);

    return loaded ? (
        <Iframe
            info={info}
            displayName="Участник"
            locationHost={_locationHost}
            meetId={confId}
        />
    ) : null;
}

function mapStateToProps(state) {
    return {
        _externalApi: state.app.externalApi
    };
}

export default connect(mapStateToProps)(UsdMeet);
